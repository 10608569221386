<template>
  <div style="height: 100%" v-bar>
    <div>
      <v-container class="lighten-5 dashboard">
        <DashboardSkeltonLoader v-if="!showGraph" />
        <v-row v-else>
          <v-col cols="12" sm="12" md="6" class="px-5 my-5">
            <v-card class="mx-auto" tile>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">Books out of stock</div>
                  <v-list-item-title class="headline mb-1">
                    {{ bookCard.booksDistributed }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >Total books now in stock
                    {{ bookCard.booksInStock }}</v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-sheet
                  color="error"
                  elevation="2"
                  height="100"
                  width="100"
                  class="text-center dash-board-card-icon"
                >
                  <v-row style="height: 100px">
                    <v-col align-self="center">
                      <v-icon size="90">mdi-book-open-variant</v-icon>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-list-item>

              <v-card-actions>
                <router-link link to="/books">
                  <v-btn tile color="success" class="ml-2 mb-2" tabindex="2">
                    Show details
                  </v-btn>
                </router-link>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="px-5 my-5">
            <v-card class="mx-auto" tile>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">Members with dues</div>
                  <v-list-item-title class="headline mb-1">
                    {{ dueCard.membersWithDues }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >Total pending dues ₹{{
                      dueCard.totalDues
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-sheet
                  color="warning"
                  elevation="2"
                  height="100"
                  width="100"
                  class="text-center dash-board-card-icon"
                >
                  <v-row style="height: 100px">
                    <v-col align-self="center">
                      <v-icon size="90">mdi-account-cash</v-icon>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-list-item>

              <v-card-actions>
                <router-link link to="/members?withDues=true">
                  <v-btn tile color="success" class="ml-2 mb-2" tabindex="2">
                    Show details
                  </v-btn>
                </router-link>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12" class="px-5 mb-5 chart-parent">
            <v-card :class="['mx-auto', showDistribution && 'pr-6']" tile>
              <div class="chart-wrap">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="overline my-4 d-flex">
                      <span
                        >Books distributions in
                        {{
                          distributionsYearType === "Day wise"
                            ? `${distributionsMonthString}, `
                            : ""
                        }}
                        {{ distributionsYear }}</span
                      >
                      <v-select
                        dense
                        class="abstract-filter ml-4"
                        v-model="distributionsYearType"
                        :items="['Month wise', 'Day wise']"
                        label="Type"
                        @change="loadDistributionGraph"
                      >
                      </v-select>
                      <v-select
                        dense
                        class="abstract-filter ml-4"
                        v-model="distributionsYear"
                        :items="years"
                        label="Year"
                        @change="loadDistributionGraph"
                      >
                      </v-select>
                      <v-select
                        v-if="distributionsYearType === 'Day wise'"
                        dense
                        class="abstract-filter ml-4"
                        v-model="distributionsMonth"
                        :items="months"
                        label="Month"
                        @change="loadDistributionGraph"
                      >
                      </v-select>
                      <v-select
                        dense
                        class="abstract-filter ml-4"
                        clearable
                        v-model="distributionType"
                        :items="[
                          'General',
                          'Balavedhi',
                          'Reference',
                          'E-Reader'
                        ]"
                        label="Distribution Type"
                        @change="loadDistributionGraph"
                      ></v-select>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-skeleton-loader
                  v-if="!showDistribution"
                  tile
                  class="graph-skeleton-loader"
                  height="285"
                  type="image"
                ></v-skeleton-loader>
                <apexchart
                  v-else
                  type="bar"
                  height="270"
                  :options="{
                    colors: ['#15b998'],
                    chart: {
                      offsetX: 3,
                      width: '100%',
                      dropShadow: {
                        enabled: true,
                        color: '#555',
                        top: 5,
                        left: 7,
                        blur: 3,
                        opacity: 0.2
                      },
                      zoom: {
                        enabled: false
                      },
                      toolbar: {
                        show: false
                      }
                    },
                    plotOptions: {
                      bar: {
                        dataLabels: {
                          position: 'top'
                        },
                        barWidth: '10%'
                      }
                    },
                    dataLabels: {
                      enabled: true,
                      style: {
                        colors: ['#000']
                      },
                      offsetY: -18
                    },
                    stroke: {
                      curve: 'straight'
                    },
                    grid: {
                      row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                      }
                    },
                    xaxis: {
                      categories: distributionsMonthNames
                    },
                    yaxis: {
                      decimalsInFloat: 0,
                      labels: {
                        formatter: (val) => (val === Infinity ? ' ' : val)
                      }
                    }
                  }"
                  :series="distributionsSeries"
                ></apexchart>
                <v-skeleton-loader
                  v-else
                  tile
                  type="image"
                  height="285"
                  class="graph-skeleton-loader"
                >
                </v-skeleton-loader>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12" class="px-5 mb-5 chart-parent">
            <v-card class="mx-auto pr-6" tile>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">
                    Books which are not yet been returned in the last 12 months
                  </div>
                </v-list-item-content>
              </v-list-item>
              <apexchart
                v-if="showGraph"
                type="bar"
                height="270"
                :options="{
                  colors: ['#15b998'],
                  chart: {
                    offsetX: 3,
                    width: '100%',
                    dropShadow: {
                      enabled: true,
                      color: '#555',
                      top: 5,
                      left: 7,
                      blur: 3,
                      opacity: 0.2
                    },
                    zoom: {
                      enabled: false
                    },
                    toolbar: {
                      show: false
                    }
                  },
                  plotOptions: {
                    bar: {
                      dataLabels: {
                        position: 'top'
                      },
                      barWidth: '10%'
                    }
                  },
                  dataLabels: {
                    enabled: true,
                    style: {
                      colors: ['#000']
                    },
                    offsetY: -18
                  },
                  stroke: {
                    curve: 'straight'
                  },
                  grid: {
                    row: {
                      colors: ['#f3f3f3', 'transparent'],
                      opacity: 0.5
                    }
                  },
                  xaxis: {
                    categories: notRetunedMonthNames
                  },
                  yaxis: {
                    decimalsInFloat: 0
                  }
                }"
                :series="notRetunedSeries"
              ></apexchart>
              <v-skeleton-loader
                v-else
                tile
                type="image"
                height="285"
                class="graph-skeleton-loader"
              >
              </v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { useDashboard } from "@/modules";
import DashboardSkeltonLoader from "@/components/DashboardSkeltonLoader";

export default {
  setup: useDashboard,
  components: { DashboardSkeltonLoader }
};
</script>

<style scoped lang="scss">
.dash-board-card-icon {
  margin-top: -50px;
  margin-right: -25px;
}

.dashboard {
  .v-card {
    overflow: visible;
  }
  .abstract-filter {
    width: 150px;
    text-transform: none;
    flex: none;
  }
  .chart-parent {
    & > .v-sheet {
      overflow-x: auto;
      overflow-y: hidden;
      .chart-wrap {
        min-width: 600px;
      }
    }
  }
}
</style>
